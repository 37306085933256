<template>
  <div>
    <div>
      <label for="exampleFormControlSelect1"
        ><h5><i class="bi bi-pencil-square"></i> 진행사항 관리</h5></label
      >
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            팀별</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            단계별</a
          >
        </li>
        <!-- <li class="nav-item" role="presentation">
        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">이름</a>
      </li> -->
      </ul>
      <div class="tab-content" id="myTabContent">
        <!-- 부서(팀)검색 -->
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div class="form-group" style="margin-top: 10px">
            <!-- @change="changeDept(this)" -->
            <select
              class="form-control"
              id="dept"
              name="dept"
              v-model="dept"
              @change="changeDept(this)"
            >
              <option
                v-for="deptCategory in deptCategorys"
                :key="deptCategory.id"
                v-bind:value="deptCategory.dept"
                :id="deptCategory.dept"
                :selected="deptCategory.dept == currentDept"
              >
                {{ deptCategory.dept }}
              </option>
            </select>
          </div>

          <!-- 리스트 시작 -->
          <div class="accordion" id="accordionExample">
            <div
              class="card"
              v-for="manageDeptList in getManageDeptList"
              :key="manageDeptList.id"
            >
              <div
                class="card-header"
                :id="'headingOne' + manageDeptList.id"
                @click="getStatusList(manageDeptList)"
              >
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseOne' + manageDeptList.id"
                    aria-expanded="true"
                    :aria-controls="'#collapseOne' + manageDeptList.id"
                    style="text-decoration-line: none"
                  >
                    <span style="color: #1d2124">
                      {{ getmoment(manageDeptList.regdate) }}
                    </span>
                    <span style="color: #dee2e6">|</span>
                    {{ manageDeptList.membername }}
                    <span style="color: #dee2e6">|</span>
                    <span style="color: #1d2124">
                      {{ manageDeptList.dept }}</span
                    >
                  </button>
                </h2>
              </div>

              <div
                :id="'collapseOne' + manageDeptList.id"
                class="collapse"
                :aria-labelledby="'headingOne' + manageDeptList.id"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      style="background: ghostwhite"
                    ></li>
                    <li class="list-group-item">
                      <b>1. 구분:</b>
                      {{ getSector(manageDeptList.sector) }}
                    </li>
                    <li class="list-group-item">
                      <b>2. 백신 접종:</b>
                      {{ getVaccineCode(manageDeptList.vaccine) }}
                    </li>
                    <li class="list-group-item">
                      <b>3. 발생 경로</b>: {{ manageDeptList.path }}
                    </li>
                    <!-- <li class="list-group-item">3. 전파자: {{manageList.spreader}}</li> -->
                    <li class="list-group-item">
                      <b>4. 최초 PL 의견:</b>
                      <p v-html="getHtmlConvert(manageDeptList.pl)"></p>
                    </li>        
                    <li class="list-group-item" v-if="manageDeptList.spreader">
                      <b>5. 주변 근무자:</b><p> {{ manageDeptList.spreader }} </p>
                    </li>                            
                  </ul>
                  <div style="text-align: center; margin-top: 10px">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      @click="SetPlOpinion(manageDeptList)"
                    >
                      PL 의견 등록
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-success"
                      @click="SetPmoOpinion(manageDeptList)"
                      style="margin-left: 30px"
                    >
                      PMO 가이드
                    </button>
                  </div>
                  <table
                    class="table table-bordered table-hover"
                    style="margin-top: 12px"
                  >
                    <thead>
                      <tr>
                        <th scope="col-3">등록일</th>
                        <th scope="col-3">구분</th>
                        <th scope="col-3">의견</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="statusList in statusLists"
                        :key="statusList.id"
                      >
                        <td colspan="3" class=":not">
                          <table>
                            <tr style="background: aliceblue;">
                              <td>단계</td>
                              <td colspan="2">
                                {{ getProcessDetail(statusList.sector) }}
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 25%">
                                {{ getmomenttime(statusList.regdate) }}
                              </td>
                              <td style="width: 10%">
                                {{ statusList.opndiv }}<span style="font-size: 10px;" v-if="statusList.writer"> ({{ statusList.writer }})</span>
                              </td>
                              <td>
                                <p
                                  v-html="getHtmlConvert(statusList.opinion)"
                                ></p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- 최초 화면 시 안내 문구 -->
          <div v-show="!dept" class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne1">
                <h6 class="mb-0">카테고리에서 팀을 선택해주세요.</h6>
              </div>
            </div>
          </div>

          <!-- 리스트 종료 -->
        </div>

        <!-- 단계별 -->
        <div
          class="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <!-- 감염 발생 구분 -->
          <div class="form-group" style="margin-top: 10px">
            <select
              class="form-control"
              id="sectorcate"
              name="sectorcate"
              @change="onChange(this)"
              v-model="sectorcate"
            >
              <option
                v-for="sectorCategory in sectorCategorys"
                :key="sectorCategory.id"
                v-bind:value="sectorCategory.codeno"
                :id="sectorCategory.codenno"
              >
                {{ sectorCategory.codename }}
              </option>
            </select>
          </div>
          <!-- 리스트 시작 -->
          <div class="accordion" id="accordionExample">
            <div
              class="card"
              v-for="manageList in getManageList"
              :key="manageList.id"
            >
              <div
                class="card-header"
                :id="'headingOne' + manageList.id"
                @click="getStatusList(manageList)"
              >
                <h2 class="mb-0">
                  <button
                    class="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapseOne' + manageList.id"
                    aria-expanded="true"
                    :aria-controls="'#collapseOne' + manageList.id"
                  >
                    {{ getmoment(manageList.regdate) }} |
                    {{ manageList.membername }} | {{ manageList.dept }}
                  </button>
                </h2>
              </div>

              <div
                :id="'collapseOne' + manageList.id"
                class="collapse"
                :aria-labelledby="'headingOne' + manageList.id"
                data-parent="#accordionExample"
              >
                <div class="card-body" style="padding: 0.5rem">
                  <ul class="list-group">
                    <li
                      class="list-group-item"
                      style="background: ghostwhite"
                    ></li>
                    <li class="list-group-item">
                      <b>1. 구분:</b>
                      {{ getSector(manageList.sector) }}
                    </li>
                    <li class="list-group-item">
                      <b>2. 백신 접종:</b>
                      {{ getVaccineCode(manageList.vaccine) }}
                    </li>
                    <li class="list-group-item">
                      <b>3. 발생 경로</b>: {{ manageList.path }}
                    </li>
                    <!-- <li class="list-group-item">3. 전파자: {{manageList.spreader}}</li> -->
                    <li class="list-group-item">
                      <b>4. 최초 PL 의견:</b>
                      <p v-html="getHtmlConvert(manageList.pl)"></p>
                    </li>
                    <li class="list-group-item" v-if="manageList.spreader">
                      <b>5. 주변 근무자:</b><p> {{ manageList.spreader }} </p>
                    </li>                         
                  </ul>
                  <div style="text-align: center; margin-top: 10px">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      @click="SetPlOpinion(manageList)"
                    >
                      PL 의견 등록
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-success"
                      @click="SetPmoOpinion(manageList)"
                      style="margin-left: 30px"
                    >
                      PMO 가이드
                    </button>
                  </div>
                  <table
                    class="table table-bordered table-hover"
                    style="margin-top: 12px"
                  >
                    <thead>
                      <tr style="text-align: center">
                        <th scope="col-3">등록일</th>
                        <th scope="col-3">구분</th>
                        <th scope="col-3">의견</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="statusList in statusLists"
                        :key="statusList.id"
                      >
                        <td colspan="3" class=":not">
                          <table>
                            <tr style="background: aliceblue;">
                              <td>단계</td>
                              <td colspan="2">
                                {{ getProcessDetail(statusList.sector) }}
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 25%">
                                {{ getmomenttime(statusList.regdate) }}
                              </td>
                              <td style="width: 10%">
                                {{ statusList.opndiv }}<span style="font-size: 10px;" v-if="statusList.writer"> ({{ statusList.writer }})</span>
                              </td>
                              <td>
                                <p
                                  v-html="getHtmlConvert(statusList.opinion)"
                                ></p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- 리스트 종료 -->

          <!-- 최초 화면 시 안내 문구 -->
          <div v-show="!sectorcate" class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne1">
                <h6 class="mb-0">카테고리에서 선택해주세요.</h6>
              </div>
            </div>
          </div>
        </div>

        <!-- 이름 -->
        <div
          class="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        ></div>
      </div>
    </div>
    <!-- 바로가기 일시 중지 함 -->
    <!-- <div>
      <div class="row" v-if="!sector">
        <div class="col-12">
          <div class="list-group" id="list-tab" role="tablist">
            <a class="list-group-item list-group-item-action" id="list-home-list" data-toggle="list" @click="isSelectSector(sectorCategory.codeno);" role="tab" aria-controls="home" v-for="sectorCategory in sectorCategorys"  :key="sectorCategory.id"  v-bind:value="sectorCategory.codename" >{{sectorCategory.codename}} </a>            
          </div>
        </div>       
      </div>
    </div> -->

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form
            name="regManageForm"
            v-on:submit="submitManageForm"
            id="regManageForm"
          >
            <div class="modal-header">
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="color: blue"
              >
                <span id="plpmo"></span>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="modalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <input type="hidden" name="opndiv" id="opndiv" v-model="opndiv" />
            <input
              type="hidden"
              name="memberno"
              id="memberno"
              v-model="PersonGetData.memberno"
            />
            <input
              type="hidden"
              name="membername"
              id="membername"
              v-model="PersonGetData.membername"
            />
            <input
              type="hidden"
              name="dept"
              id="dept"
              v-model="PersonGetData.dept"
            />
            <input
              type="hidden"
              name="parentId"
              id="parentId"
              v-model="PersonGetData.id"
            />
            <input
              type="hidden"
              name="writer"
              id="writer"
              v-model="writer"
            />              
            <div class="modal-body">
              <div class="form-group">
                <label for="recipient-name" class="col-form-label"
                  ><b>진행 상황:</b></label
                >
                <select
                  class="form-control"
                  id="sector"
                  name="sector"
                  v-model="sector"
                >
                  <option
                    v-for="processCategory in processCategorys"
                    :key="processCategory.id"
                    v-bind:value="processCategory.codeno"
                    :id="processCategory.codenno"
                  >
                    {{ processCategory.codename }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="message-text" class="col-form-label"
                  ><b>의견 내용:</b></label
                >
                <textarea
                  class="form-control"
                  id="opinion"
                  name="opinion"
                  rows="5"
                  v-model="opinion"
                  placeholder="진행 상태에 따른 상세 설명을 기록해주세요."
                ></textarea>
              </div>
              <div class="form-group" v-show="plpmodiv">
                <label for="message-text" class="col-form-label"
                  >SMS 발송 - PL :</label
                >
                <input
                  type="checkbox"
                  name="smsyn"
                  id="smsyn"
                  data-toggle="toggle"
                  data-size="lg"
                  style="margin-left: 10px"
                />

                <label
                  for="message-text"
                  style="margin-left: 20px"
                  class="col-form-label"
                  >대상자 :{{receiveMembername}}</label
                >
                <input
                  type="checkbox"
                  name="membersmsyn"
                  id="membersmsyn"
                  data-toggle="toggle"
                  data-size="lg"
                  style="margin-left: 10px"
                />

                <input
                  type="hidden"
                  name="plphonenumber"
                  id="plphonenumber"
                  v-model="plphonenumber"
                />
                <input
                  type="hidden"
                  name="membertel"
                  id="membertel"
                  v-model="PersonGetData.tel"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">등록</button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import manageService from "../services/manageService";
import statusService from "../services/statusService";
import codeService from "../services/codeService";
import loginService from "../services/LoginService";

import moment from "moment";
import $ from "jquery";
//import modal  from "./OpenModal.vue";

export default {
  name: "list",
  component: {},
  data() {
    return {
      sectorcate: "",
      title: "",
      manageLists: {},
      deptCategorys: {},
      currentIndex: -1,
      submitted: false,
      farmcode: localStorage.getItem("logininfo_farmcode"),
      deptCategory: "",
      currentDept: "팀(부서)를 선택하세요.",
      memberLists: [],
      isModalViewed: false,
      PersonGetData: {},
      showModal: false,
      statusLists: [],
      opndiv: "",
      sectorCategorys: [],
      processCategorys: [],
      opinion: "",
      processSectorName: "",
      dept: localStorage.getItem("dept"),
      manageDeptLists: {},
      plpmodiv: false,
      plphonenumber: "",
      sector: "",
      writer : localStorage.getItem("membername") != null ? localStorage.getItem("membername") : this.userInfo.membername,
      receiveMembername: "",
      nearMember: [],
    };
  },
  created() {
    // if (localStorage.getItem("logininfo_farmname") == null) {
    //   this.$router.push("/login");
    // }
    this.getSectorCategory();
    this.retrieveDeptCategory();
    this.getProcessCategory();
    this.changeDept();
  },
  computed: {
    getManageList: function () {
      return this.manageLists;
    },

    getManageDeptList: function () {
      return this.manageDeptLists;
    },
    // getShowModal(){
    //     return this.showModal != showModal;
    // }
  },
  methods: {
    //개행문자 인식
    getHtmlConvert(str) {
      const replacer = new RegExp("\\n", "g");
      return str.replace(replacer, "<br />");
    },
    //부서 팀별 조회하기
    retrieveDeptCategory() {
      // const data = {farmcode: this.farmcode}
      loginService
        .getDept()
        .then((response) => {
          this.deptCategorys = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //백신 코드
    getVaccineCode(vcode) {
      if (vcode == "20") return "미접종";
      if (vcode == "21") return "1차";
      if (vcode == "22") return "2차";
      if (vcode == "23") return "3차";
      if (vcode == "24") return "4차";
      return "";
    },
    //상태 기록 리스트 가져오기
    getStatusList(item) {
      const data = {
        memberno: item.memberno,
        parentId: item.id,
      };

      statusService
        .getList(data)
        .then((response) => {
          this.statusLists = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //의견 등록 저장
    submitManageForm(e) {
      e.preventDefault();
      var object = {};
      var fform = document.getElementById("regManageForm");
      var fsector = document.getElementById("sector").value;

      //PL 의견 등록 시 SMS
      var smsyn = document.getElementById("smsyn").checked;
      var fphonenumber = document.getElementById("plphonenumber").value;

      //대상사 의견 등록 시 SMS
      var membersmsyn = document.getElementById("membersmsyn").checked;
      var fmembertel = document.getElementById("membertel").value;

      //의견 등록
      var fopinion = document.getElementById("opinion").value;
      fopinion = fopinion.substr(0, 40);

      var form = new FormData(fform);
      form.forEach(function (value, key) {
        object[key] = value;
      });

      object["sector"] = fsector;

      //PL 의견 등록 시 SMS
      if (smsyn == true) object["smsyn"] = "Y";
      else object["smsyn"] = "N";

      //대상사 의견 등록 시 SMS
      if (membersmsyn == true) object["membersmsyn"] = "Y";
      else object["membersmsyn"] = "N";

      //SMS 발송값 PL만
      if (smsyn == true && membersmsyn == false) {
        object["sender"] = "01053731570";
        object["receiver"] = fphonenumber.trim();
        object["msg"] = this.receiveMembername+"님,"+fopinion;
        object["msg_type"] = "SMS";
        object["title"] = "PMO 가이드";
      }

      //SMS 발송값  PL + 대상자
      if (smsyn == true && membersmsyn == true) {
        object["sender"] = "01053731570";
        object["rec_1"] = fphonenumber.trim();
        object["msg_1"] = this.receiveMembername+"님,"+fopinion;
        object["msg_type"] = "SMS";
        object["rec_2"] = fmembertel.trim();
        object["msg_2"] = "[발신]" + fopinion;
        object["cnt"] = "2";
        object["title"] = "PMO 가이드";
      }

      //SMS 발송값 대상자만
      if (smsyn == false && membersmsyn == true) {
        object["sender"] = "01053731570";
        object["receiver"] = fmembertel.trim();
        object["msg"] = "[발신]" + fopinion;
        object["msg_type"] = "SMS";
        object["title"] = "PMO 가이드";
      }

      //   sender: 발신자 전화번호 // (최대 16bytes)
      //   rec_1: 수신자 전화번호1
      //   msg_1: 메시지 내용1	// (1~2,000Byte)
      //   msg_type: SMS(단문), LMS(장문), MMS(그림문자)
      //   cnt: 메세지 전송건수 // (1~500)

      var jsondata = JSON.stringify(object);

      if (fsector == "") {
        this.$toast.show("현재 상황을 선택해주세요.", {
          type: "success",
          position: "top-right",
        });
        return;
      }

      if (JSON.parse(jsondata).opinion == "") {
        this.$toast.show("의견을 등록해주세요.", {
          type: "success",
          position: "top-right",
        });
        return;
      }
      statusService
        .insert(jsondata)
        .then(() => {
          //console.log(response.data);
          this.message = "";
          this.$toast.show("저장되었습니다.", {
            type: "warning",
            position: "top-right",
          });

          const reData = {
            memberno: object.memberno,
            id: object.parentId,
          };

          this.getStatusList(reData);
          $("#exampleModal").modal("hide");
        })
        .catch((e) => {
          this.$toast.show("다시 확인해주세요.", {
            type: "success",
            position: "top-right",
          });
          console.log(e);
        });
    },

    SetPlOpinion(item) {
      this.opinion = "";
      this.plpmodiv = false;
      this.getProcessCategory();
      this.PersonGetData = item;
      this.plphonenumber = item.pltel;
      $("#plpmo").html("PL 의견등록");
      this.opndiv = "PL";
      $("#exampleModal").modal("show");
    },
    SetPmoOpinion(item) {
      this.opinion = "";
      this.plpmodiv = true;
      this.getProcessCategory();
      this.PersonGetData = item;
      this.receiveMembername = item.membername;
      this.plphonenumber = item.pltel;
      $("#plpmo").html("PMO 가이드");
      this.opndiv = "PMO";
      $("#exampleModal").modal("show");
    },

    //구분값 추출 이미 코드값은 불어와 있음
    getSector(item) {
      let isSector = "";
      for (var i = 0; i < this.sectorCategorys.length; i++) {
        if (this.sectorCategorys[i]["codeno"] == item) {
          isSector = this.sectorCategorys[i]["codename"];
          break;
        }
      }
      return isSector;

      //json 객체에서 문자열 찾기로 반환한다.
      // const data = {
      //   grp: "100",
      //   codeno: item, //감염발생구분
      // };
      // codeService
      //   .getListDetail(data)
      //   .then((response) => {
      //     sect = response.data;
      //     console.log("sect==>",sect)
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });

      //console.log(this.sectorCategorys);
      //return sect;
    },
    getmoment: function (ddate) {
      return moment(ddate).format("MM.DD");
    },
    getmomenttime: function (ddate) {
      return moment(ddate).format("MM.DD HH:mm");
    },

    //단계별 조회
    onChange() {
      const data = {
        sector: this.sectorcate,
      };

      // console.log(data);

      manageService
        .getList(data)
        .then((response) => {
          this.manageLists = response.data;
         // console.log("manageList ==> " + JSON.stringify(this.manageLists));


          //if(this.manageLists.spreader
          ///nearMember

        })
        .catch((e) => {
          console.log(e);
        });
    },

    //부서 팀별 조회
    changeDept() {
      const data = {
        dept: this.dept,
      };

      //console.log(data);

      manageService
        .getDeptList(data)
        .then((response) => {
          this.manageDeptLists = response.data;
          //console.log(this.manageDeptLists)
        })
        .catch((e) => {
          console.log(e);
        });
    },

    //리스트 바 선택할 때
    isSelectSector(item) {
      this.sector = item;
      const data = {
        sector: item,
      };

      // console.log(data);

      manageService
        .getList(data)
        .then((response) => {
          this.manageLists = response.data;
          //console.log("manageList ==> " + JSON.stringify(this.manageLists));
        })
        .catch((e) => {
          console.log(e);
        });
    },

    trgtRegister(memberList) {
      this.$router.push({
        name: "trgtRegister",
        params: {
          memberno: memberList.memberno,
          membername: memberList.membername,
          dept: memberList.dept,
          tel: memberList.tel,
        },
      });
    },
    updateTutorial(tutorial) {
      //e.preventDefault();
      // let myForm = document.getElementById("checkForm")
      // let form = new FormData(myForm);

      const data = {
        id: tutorial.id,
        words: tutorial.words,
        pray: tutorial.pray,
        opinon: tutorial.opinon,
      };
      // need to convert it before using not with XMLHttpRequest
      // for (let [key, val] of form.entries()) {
      //   Object.assign(data, { [key]: val })
      //  // console.log({ [key]: val })
      // }
      //console.log("tutorial=="+tutorial.words)
      //console.log(...form.entries())
      if (tutorial.words || tutorial.pray) {
        manageService
          .update(tutorial.id, data)
          .then(() => {
            //console.log(response.data);
            this.message = "";
            this.$toast.show("저장되었습니다.", {
              type: "warning",
              position: "top-right",
            });
          })
          .catch((e) => {
            this.$toast.show("다시 확인해주세요.", {
              type: "success",
              position: "top-right",
            });
            console.log(e);
          });
      } else {
        this.$toast.show("입력 내용이 없습니다.", {
          type: "success",
          position: "top-right",
        });
      }
    },

    // 감염 발생 경로
    getSectorCategory() {
      const data = {
        grp: "100", //감염발생구분
      };
      codeService
        .getList(data)
        .then((response) => {
          this.sectorCategorys = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 진행사항코드 가져오기
    getProcessCategory() {
      const data = {
        grp: "300", //감염발생구분
      };
      codeService
        .getList(data)
        .then((response) => {
          this.processCategorys = response.data;
          // console.log("processCategorys ==> " + JSON.stringify(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 진행사항코드 상세정보 가져오기
    getProcessDetail(item) {
      let isCategory = "";
      for (var i = 0; i < this.processCategorys.length; i++) {
        if (this.processCategorys[i]["codeno"] == item) {
          isCategory = this.processCategorys[i]["codename"];
          break;
        }
      }
      return isCategory;

      // const data = {
      //   grp: "300", //감염발생구분
      //   codeno: item,
      // };
      // console.log(data);
      // if (item == "30") return "조치없음";
      // if (item == "31") return "본인 신속항원 검사 진행중";
      // if (item == "32") return "본인 신속항원 결과 음성";
      // if (item == "33") return "본인 신속항원 결과 양성";
      // if (item == "34") return "본인 PCR 검사 진행중";
      // if (item == "35") return "본인 PCR 검사 결과 음성";
      // if (item == "36") return "본인 PCR 검사 결과 양성";
      // if (item == "37") return "정상 출근 대상(상황 종료)";
      // if (item == "38") return "재택근무 1일";
      // if (item == "39") return "재택근무 2일";
      // if (item == "40") return "재택근무 3일";
      // if (item == "41") return "재택근무 4일";
      // if (item == "42") return "재택근무 5일";
      // if (item == "43") return "재택근무 6일";
      // if (item == "44") return "재택근무 7일";
      // if (item == "45") return "재택근무 8일";
      // if (item == "46") return "재택근무 9일";
      // if (item == "47") return "재택근무 10일";

      // codeService
      //   .getListDetail(data)
      //   .then((response) => {
      //     sectorname = response.data[0].codename;
      //     console.log("processCategorys ==> " +  sectorname);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      //   return sectorname;
    },
  },
  mounted() {
    //this.retrieveDeptCategory();
  },
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.done {
  background: oldlace;
}
</style>
