import http from "../http-common";

class CodeService {
  getList(data) {
    return http.post(`/code/group/list`, data);
  }   

  getListDetail(data) {
    return http.post(`/code/group/listDetail`, data);
  }   
}

export default new CodeService();
