import http from "../http-common";

class StatusService {
  insert(data) {
    return http.post(`/status/insert/`, data);
  }  
  getList(data) {
    return http.post(`/status/list/`, data);
  }   
}

export default new StatusService();